import { useQuery } from '@tanstack/react-query';
import { getData } from './base';

export interface RolesInterface {
	id: string;
	name: string;
	description: string;
}

const useRoles = () => useQuery<RolesInterface[], Error>(['roles'], getData('/membership/roles'));

const useRole = (id: string) =>
	useQuery<RolesInterface, Error>(['role'], getData(`/membership/roles/${id}`));

export default {
	useRoles,
	useRole,
};

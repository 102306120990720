import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';
import './styles/styles.scss';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';

const queryClient = new QueryClient({
	queryCache: new QueryCache({
		onError: (error: any) => {
			if (axios.isAxiosError(error) && error.response?.status === 401) {
				/* localStorage.removeItem('access_token');
				window.location.href = '/login'; */
			}
		},
	}),
	defaultOptions: {
		queries: {
			retry: false,
		},
	},
});

const children = (
	<Auth0Provider
		domain='dev-op5w7qwauka68s6r.us.auth0.com'
		clientId='yuhsUDthwcWDbI7j6ZWurRAy0Si1hlPL'
		authorizationParams={{
			redirect_uri: `${window.location.origin}/admin`,
			audience: 'https://dev-op5w7qwauka68s6r.us.auth0.com/api/v2/',
			scope: 'read:current_user update:current_user_metadata openid profile email user',
		}}
		cacheLocation='localstorage'>
		<Router>
			<AuthContextProvider>
				<ThemeContextProvider>
					<QueryClientProvider client={queryClient}>
						<App />
						<ReactQueryDevtools initialIsOpen />
					</QueryClientProvider>
				</ThemeContextProvider>
			</AuthContextProvider>
		</Router>
	</Auth0Provider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';

interface LoadingProps {
	visible?: boolean;
}

const Loading = (props: LoadingProps) => {
	const { visible } = props;
	return (
		visible && (
			<div className='text-center h-100 d-flex flex-row justify-content-center'>
				<div className='spinner-grow  text-primary align-self-center' role='status' />
			</div>
		)
	);
};

export default Loading;

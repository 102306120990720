import React, { lazy } from 'react';
import CustomRoutes from '@this/impl/routes/content.routes';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};

const CUSTOMERS = {
	CUSTOMERS: lazy(() => import('../pages/presentation/customers/Customers')),
	COMMERCIAL_PROFILE: lazy(() => import('../pages/presentation/customers/commercial/Commercial')),
	RESIDENTIAL_PROFILE: lazy(
		() => import('../pages/presentation/customers/residential/Residential'),
	),
	NEW_CUSTOMER: lazy(() => import('../pages/presentation/customers/new-customer/NewCustomer')),
	EDIT_CUSTOMER: lazy(() => import('../pages/presentation/customers/edit-customer/EditCustomer')),
};

const PIPELINE = {
	PIPELINE: lazy(() => import('../pages/presentation/pipeline/Pipeline')),
};

const SERVICES = {
	SERVICES: lazy(() => import('../pages/presentation/services/Services')),
	SERVICE: lazy(() => import('../pages/presentation/services/service/Service')),
	NEW_SERVICE: lazy(() => import('../pages/presentation/services/new-service/NewService')),
	EDIT_SERVICE: lazy(() => import('../pages/presentation/services/edit-service/EditService')),
};

const USERS = {
	USERS: lazy(() => import('../pages/presentation/users/Users')),
	NEW_USER: lazy(() => import('../pages/presentation/users/new-user/NewUser')),
	USER: lazy(() => import('../pages/presentation/users/user')),
	EDIT_USER: lazy(() => import('../pages/presentation/users/edit-user/EditUser')),
};

const MESSAGES = {
	MESSAGES: lazy(() => import('../pages/presentation/messages/Messages')),
};

const SCHEDULE = {
	SCHEDULE: lazy(() => import('../pages/presentation/schedule/Schedule')),
};

const INVOICE = {
	INVOICE: lazy(() => import('../pages/presentation/invoices/Invoices')),
};

const presentation = [
	{
		path: '/',
		element: <CUSTOMERS.CUSTOMERS />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: '/admin/customers',
		element: <CUSTOMERS.CUSTOMERS />,
	},
	{
		path: '/admin/customers/residential/:id',
		element: <CUSTOMERS.RESIDENTIAL_PROFILE />,
	},
	{
		path: '/admin/customers/commercial/:id',
		element: <CUSTOMERS.COMMERCIAL_PROFILE />,
	},
	{
		path: '/admin/customers/create',
		element: <CUSTOMERS.NEW_CUSTOMER />,
	},
	{
		path: '/admin/customers/edit/:id',
		element: <CUSTOMERS.EDIT_CUSTOMER />,
	},
	{
		path: '/admin/pipeline',
		element: <PIPELINE.PIPELINE />,
	},
	{
		path: '/admin/services',
		element: <SERVICES.SERVICES />,
	},
	{
		path: '/admin/services/:id',
		element: <SERVICES.SERVICE />,
	},
	{
		path: '/admin/services/create',
		element: <SERVICES.NEW_SERVICE />,
	},
	{
		path: '/admin/services/edit/:id',
		element: <SERVICES.EDIT_SERVICE />,
	},
	{
		path: '/admin/users',
		element: <USERS.USERS />,
	},
	{
		path: '/admin/users/create',
		element: <USERS.NEW_USER />,
	},
	{
		path: '/admin/users/edit/:id',
		element: <USERS.EDIT_USER />,
	},
	{
		path: '/admin/users/:id',
		element: <USERS.USER />,
	},
	{
		path: '/admin/messages',
		element: <MESSAGES.MESSAGES />,
	},
	{
		path: '/admin/schedule',
		element: <SCHEDULE.SCHEDULE />,
	},
	{
		path: '/admin/invoices',
		element: <INVOICE.INVOICE />,
	},
];

const contents = [...presentation, ...CustomRoutes];

export default contents;

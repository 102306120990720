import { useQuery } from '@tanstack/react-query';
import { getData, postData } from './base';
import { UsersInterface } from './users';

export enum OrderStatus {
	LeadNotContacted = 'LeadNotContacted',
	LeadContacting = 'LeadContacting',
	EstimateAwaitingApproval = 'EstimateAwaitingApproval',
	EstimateScheduled = 'EstimateScheduled',
	EstimateNotScheduled = 'EstimateNotScheduled',
	EstimateEnRoute = 'EstimateEnRoute',
	EstimateInProgress = 'EstimateInProgress',
	EstimateDone = 'EstimateDone',
	EstimateApproved = 'EstimateApproved',
	WorkNotScheduled = 'WorkNotScheduled',
	WorkPartsOnOrder = 'WorkPartsOnOrder',
	WorkScheduled = 'WorkScheduled',
	WorkInProgress = 'WorkInProgress',
	WorkEnRoute = 'WorkEnRoute',
	WorkDone = 'WorkDone',
	InvoiceOverdue10Days = 'InvoiceOverdue10Days',
	InvoiceNotSent = 'InvoiceNotSent',
	InvoiceSent = 'InvoiceSent',
	InvoiceDue5Days = 'InvoiceDue5Days',
	Done = 'Done',
	Lost = 'Lost',
}

export const GroupedOrderStatus = {
	Lead: [OrderStatus.LeadNotContacted, OrderStatus.LeadContacting],
	Estimate: [
		OrderStatus.EstimateAwaitingApproval,
		OrderStatus.EstimateScheduled,
		OrderStatus.EstimateNotScheduled,
		OrderStatus.EstimateEnRoute,
		OrderStatus.EstimateInProgress,
		OrderStatus.EstimateDone,
		OrderStatus.EstimateApproved,
	],
	Work: [
		OrderStatus.WorkNotScheduled,
		OrderStatus.WorkPartsOnOrder,
		OrderStatus.WorkScheduled,
		OrderStatus.WorkInProgress,
		OrderStatus.WorkEnRoute,
		OrderStatus.WorkDone,
	],
	Invoice: [
		OrderStatus.InvoiceOverdue10Days,
		OrderStatus.InvoiceNotSent,
		OrderStatus.InvoiceSent,
		OrderStatus.InvoiceDue5Days,
	],
	Done: [OrderStatus.Done],
	Lost: [OrderStatus.Lost],
};

export const OrderStatusCategories = {
	[OrderStatus.LeadNotContacted]: 'Lead',
	[OrderStatus.LeadContacting]: 'Lead',
	[OrderStatus.EstimateAwaitingApproval]: 'Estimate',
	[OrderStatus.EstimateScheduled]: 'Estimate',
	[OrderStatus.EstimateNotScheduled]: 'Estimate',
	[OrderStatus.EstimateEnRoute]: 'Estimate',
	[OrderStatus.EstimateInProgress]: 'Estimate',
	[OrderStatus.EstimateDone]: 'Estimate',
	[OrderStatus.EstimateApproved]: 'Estimate',
	[OrderStatus.WorkNotScheduled]: 'Work',
	[OrderStatus.WorkPartsOnOrder]: 'Work',
	[OrderStatus.WorkScheduled]: 'Work',
	[OrderStatus.WorkInProgress]: 'Work',
	[OrderStatus.WorkEnRoute]: 'Work',
	[OrderStatus.WorkDone]: 'Work',
	[OrderStatus.InvoiceOverdue10Days]: 'Invoice',
	[OrderStatus.InvoiceNotSent]: 'Invoice',
	[OrderStatus.InvoiceSent]: 'Invoice',
	[OrderStatus.InvoiceDue5Days]: 'Invoice',
	[OrderStatus.Done]: 'Done',
	[OrderStatus.Lost]: 'Lost',
};

export enum ContactRoute {
	Email = 'Email',
	Phone = 'Phone',
}

export enum Concept {
	Work = 'Work',
	Estimate = 'Estimate',
}

export type ContactHistory = {
	date: Date;
	contactRoute: string;
	note: string;
	_id: string;
};

export type TimeLog = {
	date: Date;
	responsable: string;
	concept: Concept;
	time: string;
};

export type ActivityLog = {
	date: Date;
	responsable: string;
	update: string;
};

export type Multimedia = {
	url: string;
};

export enum ScheduleType {
	Work = 'Work',
	Estimate = 'Estimate',
}

export const ScheduleTypeTransitions: { [key: string]: ScheduleType } = {
	[OrderStatus.EstimateNotScheduled]: ScheduleType.Estimate,
	[OrderStatus.EstimateScheduled]: ScheduleType.Estimate,
	[OrderStatus.LeadContacting]: ScheduleType.Estimate,
	[OrderStatus.EstimateApproved]: ScheduleType.Work,
	[OrderStatus.WorkNotScheduled]: ScheduleType.Work,
	[OrderStatus.WorkScheduled]: ScheduleType.Work,
	[OrderStatus.WorkPartsOnOrder]: ScheduleType.Work,
};

export interface WorkOrderInterface {
	id: string;
	orderNumber?: number;
	orderId?: string;
	customerId: string;
	orderStatus: OrderStatus;
	creationDate: Date;
	technician?: string;
	client: string;
	address?: string;
	description: string;
	total?: string;
	contactHistory?: ContactHistory[] | [];
	timeLog?: TimeLog[] | [];
	activityLog?: ActivityLog[] | [];
	multimedia?: Multimedia[];
	assignedTechnicians?: UsersInterface[];
}

export interface OrderUpdateBody {
	orderId: string;
	targetStatus: OrderStatus;
}

export interface OrderMultimediaRequest {
	multimedia: Multimedia[];
}

const useOrders = () =>
	useQuery<WorkOrderInterface[], Error>(['orders'], getData('/orders/'), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

const useCustomerOrder = (id: string) =>
	useQuery<WorkOrderInterface[], Error>(['order'], getData(`/orders/?customerId=${id}`), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

const useOrder = (id: string) =>
	useQuery<WorkOrderInterface, Error>(['order'], getData(`/orders/${id}`), {
		refetchOnWindowFocus: false,
		refetchOnMount: true,
	});

const updateOrder = async (body: OrderUpdateBody) => {
	const { data } = await postData(`/orders/${body.orderId}/event`, body);
	return data;
};

const useTimelog = (orderId: string) =>
	useQuery<any, Error>(['timelog'], getData(`/orders/job-activities?orderId=${orderId}`), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

const useTechnicians = (orderId: string) =>
	useQuery<any, Error>(['technicians'], getData(`/orders/schedules?orderId=${orderId}`), {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
	});

const useOrderFiles = (orderId: string) =>
	useQuery<string[], Error>(['files'], getData(`/orders/files/list/${orderId}`));

export default {
	useOrder,
	useOrders,
	useCustomerOrder,
	updateOrder,
	useTimelog,
	useTechnicians,
	useOrderFiles,
};

import React, { ReactNode, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CustomSubHeader from '@this/impl/pages/public/aside/subheader';
import CustomMainAside from '@this/impl/pages/public/aside/main';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, {
	AsideBody,
	AsideFoot,
	AsideHead,
	AsideSubHeader,
} from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(localStorage.getItem('facit_asideDocStatus') === 'true' || false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideSubHeader>
				<CustomSubHeader />
			</AsideSubHeader>
			<AsideBody>
				<CustomMainAside />
			</AsideBody>
			<AsideFoot>
				<User collapsible asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;

import Modal, { ModalHeader, ModalBody, ModalFooter } from '@this/template/components/bootstrap/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Icon from '@this/template/components/icon/Icon';
import FormGroup from '@this/template/components/bootstrap/forms/FormGroup';

import Input from '@this/template/components/bootstrap/forms/Input';
import api from '@this/impl/api';
import Select from '@this/template/components/bootstrap/forms/Select';
import Button from '@this/template/components/bootstrap/Button';
import { useDebounce } from 'react-use';
import TagsField from '../../components/tags.field';

const SearchHeader = () => {
	const [searchParams] = useSearchParams();
	// const categoriesQuery = api.Contents.useCategories(); // TODO remove or improve this, was causing errors
	const categoriesQuery = { all: { data: [] } }
	const structuresQuery = api.Contents.useStructures();
	const navigate = useNavigate();

	const [filter, setFilter] = useState('');
	const [category, setCategory] = useState<string>();
	const [parent, setParent] = useState<string>();
	const [labels, setLabels] = useState<string[]>();

	const handleFilterChange = (e: any) => {
		setFilter(e.target.value);
	};

	const search = () =>
		navigate({
			pathname: `/documents/search`,
			search: `?q=${filter}`,
		});

	const handleKeyDown = (e: any) => {
		if (e.key === 'Enter') search();
	}

	const [searchModalStatus, setSearchModalStatus] = useState(false);

	const handleAdvancedSearch = () => {
		setSearchModalStatus(false);

		const params = [];
		if (filter) {
			params.push(`q=${filter}`);
		}
		if (category) {
			params.push(`categories=${category}`);
		}
		if (parent) {
			params.push(`parent=${parent}`);
		}
		if (labels) {
			params.push(`labels=${labels.join(',')}`);
		}

		navigate({
			pathname: `/documents/search`,
			search: `?${params.join('&')}`,
		});
	};

	const handleCategoryChange = (e: any) => {
		setCategory(e.target.value === 'none' ? undefined : e.target.value);
	};

	const handleParentChange = (e: any) => {
		setParent(e.target.value === 'none' ? undefined : e.target.value);
	}

	const handleLabelsChanges = (e: any) => {
		setLabels(e);
	}

	const openSearch = () => {
		setSearchModalStatus(true);
	}

	useEffect(() => {
		if (searchParams.has('q')) {
			setFilter(searchParams.get('q') ?? '');
		}
		if (searchParams.has('categories')) {
			setCategory(searchParams.get('categories') ?? '');
		}
		if (searchParams.has('parent')) {
			setParent(searchParams.get('parent') ?? '');
		}
		if (searchParams.has('labels')) {
			setLabels(searchParams.get('labels')?.split(',') ?? []);
		}
	}, [searchParams]);

	return (
		<div>
			<div className='input-group'>
				<Input
					id='filters'
					name='filters'
					className='form-control rounded-start-pill ps-4'
					value={filter ?? ''}
					onChange={handleFilterChange}
					onKeyDown={handleKeyDown}
				/>
				<span className='input-group-text rounded-end-pill' id='Buscar en la Admin'>
					<a
						href='#'
						className='link-dark'
						data-bs-toggle='modal'
						data-bs-target='#searchModal'
						onClick={openSearch}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							fill='currentColor'
							className='bi bi-sliders2'
							viewBox='0 0 16 16'>
							<path
								fillRule='evenodd'
								d='M10.5 1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4H1.5a.5.5 0 0 1 0-1H10V1.5a.5.5 0 0 1 .5-.5ZM12 3.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Zm-6.5 2A.5.5 0 0 1 6 6v1.5h8.5a.5.5 0 0 1 0 1H6V10a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5ZM1 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 1 8Zm9.5 2a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V13H1.5a.5.5 0 0 1 0-1H10v-1.5a.5.5 0 0 1 .5-.5Zm1.5 2.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z'
							/>
						</svg>
					</a>
				</span>
			</div>

			<Modal
				setIsOpen={setSearchModalStatus}
				isOpen={searchModalStatus}
				isStaticBackdrop
				isScrollable
				size="lg"
				data-tour='search-modal'>
				<ModalHeader setIsOpen={setSearchModalStatus} className='border-bottom'>
					<h2>Busqueda Avanzada</h2>
				</ModalHeader>
				<ModalBody>
					<div className='h-auto d-inline-block w-100'>
						<div className='d-flex flex-column p-3 h-100'>
							<div className='align-self-stretch h-auto d-inline-block'>
								<FormGroup
									id='parent'
									label='Tipo'
									placeholder='busqueda'
									labelClassName='text-capitalize'
									childWrapperClassName='d-flex'
									className='mb-3'>
									<Select
										id='parent'
										name='parent'
										ariaLabel='Estructura'
										onChange={handleCategoryChange}
										value={category}
										list={[
											{
												value: 'none',
												text: 'Todos',
											},
											...(categoriesQuery.all.data?.map((r: any) => ({
												value: r._id,
												text: r.name,
											})) ?? []),
										]}
									/>
								</FormGroup>
								<FormGroup
									id='filterQuery	'
									label='Palabras Clave'
									placeholder='busqueda'
									labelClassName='text-capitalize'
									childWrapperClassName='d-flex'
									className='mb-3'>
									<Input
										id='filter'
										name='filter'
										className='flex-grow-1'
										value={filter ?? ''}
										onChange={handleFilterChange}
									/>
								</FormGroup>
								<FormGroup
									id='structure'
									label='Estructura'
									placeholder='busqueda'
									labelClassName='text-capitalize'
									childWrapperClassName='d-flex'
									className='mb-3'>
									<Select
										id='parent'
										name='parent'
										ariaLabel='Estructura'
										onChange={handleParentChange}
										value={parent}
										list={[
											{
												value: 'none',
												text: 'Todos',
											},
											...(structuresQuery.all.data?.map((r: any) => ({
												value: r.id,
												text: r.name === 'fwi-Admin-root' ? 'No relacionado' : r.name,
											})) ?? []),
										]}
									/>
								</FormGroup>
								<FormGroup
									id='labels'
									label='Etiquetas'
									placeholder='busqueda'
									labelClassName='text-capitalize'
									childWrapperClassName='d-flex'
									className='mb-3'>
									<TagsField id='labels' name='labels' value={labels} onChange={handleLabelsChanges} />
								</FormGroup>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='d-flex flex-column h-90 border-top'>
					<div className='d-flex  p-3 mt-2'>
						<Button color='primary' className='w-100 m-2' onClick={handleAdvancedSearch}>
							Buscar
						</Button>
					</div>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default SearchHeader;

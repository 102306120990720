import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jwt-decode';
import axios from 'axios';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import Profile from '@this/impl/api/models/profile';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	token: string | undefined;
	userData: Partial<IUserProps>;
	logout(): any;
	hasPrivilege(privileges: string[]): boolean | undefined;
	isLoggedIn(): boolean;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [token, setToken] = useState<string | undefined>(
		localStorage.getItem('access_token') || '',
	);
	const { user, isAuthenticated, getAccessTokenSilently, logout: logOut } = useAuth0();

	const logout = () => {
		logOut({ logoutParams: { returnTo: `${window.location.origin}/login` } });
		localStorage.removeItem('access_token');
		setToken(undefined); // this is been used as logout. should be replaced with a proper logout function
		// window.location.href = '/login';
	};

	const isLoggedIn = (): boolean => {
		return token !== undefined && token !== '';
	};

	const hasPrivilege = (privileges: string[]) => {
		return userData?.privileges?.some((p) => privileges.includes(p)) || userData?.sudo === true;
	};

	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	useEffect(() => {
		const checkAuth = async () => {
			if (!isAuthenticated) {
				try {
					const accessToken = await getAccessTokenSilently({
						authorizationParams: {
							scope: 'read:current_user openid profile email user',
						},
					});
					setToken(accessToken);
					localStorage.setItem('access_token', accessToken);
				} catch (e) {
					console.error('User is not authenticated:', e);
				}
			}
			if (!isAuthenticated && location.pathname !== '/approve-estimate') {
				console.log('User is not authenticated, redirecting to login page');
				navigate('/login');
			}
		};

		checkAuth();
	}, [isAuthenticated, getAccessTokenSilently, navigate, location.pathname]);

	useEffect(() => {
		if (token && isAuthenticated && user) {
			axios.defaults.headers.common.Authorization = `Bearer ${token}`;
			const payload: any = jwt(token);
			console.log('payload', payload);
			console.log('user', user);
			const profilesPrivileges = _.flatMapDeep(
				payload.user?.profiles?.map((p: Profile) => p.privileges),
			);
			setUserData({
				id: user.sub ?? '',
				email: user.email,
				username: user.email ?? '',
				name: user.name,
				privileges: [...(payload.user?.privileges ?? []), ...profilesPrivileges],
				sudo: payload.user?.sudo ?? false,
			});
		} else {
			axios.defaults.headers.common.Authorization = '';
			setUserData({});
		}
	}, [isAuthenticated, token, user]);

	const value = useMemo(
		() => ({
			logout,
			isLoggedIn,
			token,
			userData,
			hasPrivilege,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;

import classNames from 'classnames';
import { ReactElement, forwardRef, useContext, useEffect, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import { IPageProps } from '../Page/Page';
import { ISubHeaderProps } from '../SubHeader/SubHeader';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children: ReactElement<ISubHeaderProps>[] | ReactElement<IPageProps> | ReactElement<IPageProps>[];
	className?: string;
}

const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title}` : ''}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		const { token } = useContext(AuthContext);
		const location = useLocation();
		const navigate = useNavigate();
		useEffect(() => {
			if (isProtected && token === '') {
				navigate(`../login`);
			} else if (location.pathname !== '/login' && location.pathname !== '/') {
				localStorage.setItem('redirect', location.pathname);
			}
			return () => {};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			// eslint-disable-next-line react/react-in-jsx-scope
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);

export default PageWrapper;

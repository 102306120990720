export const customersMenu = {
	id: 'customers',
	text: 'Customers',
	icon: 'Group',
	path: '/admin/customers',
};

export const pipelineMenu = {
	id: 'pipeline',
	text: 'Pipeline',
	icon: 'Equalizer',
	path: '/admin/pipeline',
};

export const contentsMenu = {
	id: 'services',
	text: 'Services',
	icon: 'Handyman',
	path: '/admin/services',
};

export const invoiceMenu = {
	id: 'invoices',
	text: 'Invoices',
	path: '/admin/invoices',
	icon: 'Description',
};

export const usersMenu = {
	id: 'users',
	text: 'Users',
	icon: 'Groups',
	path: '/admin/users',
};

export const messagesMenu = {
	id: 'messages',
	text: 'Messages',
	icon: 'Message',
	path: '/admin/messages',
};

export const adminMenu = {
	pipeline: pipelineMenu,
	customer: customersMenu,
	users: usersMenu,
	contents: contentsMenu,
	sections: invoiceMenu,
	messages: messagesMenu,
};

import { useQuery } from '@tanstack/react-query';
import { getData } from './base';
import Profile from './models/profile';
import User from './models/user';
import Group from './models/group';
import { useEntityQuery } from './entityQuery';
import ResourcePermission from './models/resource.permission';
import { Customer } from './models/customer';

const useProfiles = (id?: string) =>
	useEntityQuery<Profile>({
		name: 'profiles',
		path: '/membership/profiles',
		id,
		enabled: true,
		tree: false,
	});

const useUsers = (id?: string) =>
	useEntityQuery<User>({
		name: 'users',
		path: '/membership/users',
		id,
		enabled: true,
		tree: false,
	});

const useGroups = () => useQuery<Group[], Error>(['groups'], getData('/membership/groups'));

const usePrivileges = () =>
	useQuery<string[], Error>(['privileges'], getData('/membership/privileges'));

const useResources = (id?: string) =>
	useEntityQuery<ResourcePermission>({
		name: 'resources',
		path: '/membership/resources',
		id,
		enabled: false,
		tree: false,
	});

const useCustomers = () =>
	useQuery<Customer[], Error>(['customers'], getData('/membership/organizations'));

const useCustomer = (id: string) =>
	useQuery<Customer, Error>(['customer'], getData(`/membership/organizations/${id}`));

export default {
	useProfiles,
	useGroups,
	useUsers,
	usePrivileges,
	useResources,
	useCustomers,
	useCustomer,
};

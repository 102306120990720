import { useQuery } from '@tanstack/react-query';
import { getData } from './base';

export interface InvoiceResponse {
	status: string;
	organizationId: string;
	organizationName: string;
	orderId: string;
	amount: number;
	currency: string;
	description: string;
	invoiceStatus: string;
	creationDate: string;
	dueAt: string;
	externalId: string;
	sentAt: string;
	id: string;
}

const useInvoices = () =>
	useQuery<InvoiceResponse[], Error>(['invoices'], getData('/orders/invoices'));

const useInvoice = (invoiceId: string) =>
	useQuery<InvoiceResponse, Error>(
		['invoice'],
		getData(`/orders/invoices?invoiceId=${invoiceId}`),
	);

export default { useInvoices, useInvoice };

import React, { FC } from 'react';

interface ILogoProps {
	width?: number;
	height?: number;
	darkMode?: any;
}

const Logo: FC<ILogoProps> = ({ width = 2155, height = 854, darkMode = true }) => {
	// eslint-disable-next-line jsx-a11y/alt-text
	return (
		<img
			style={{ width: '230px' }}
			src={darkMode ? '/images/logo-light.png' : '/images/logo-dark.png'}
			alt='Logo'
		/>
	);
};

export default Logo;

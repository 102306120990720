import React from 'react';
import Popovers from '@this/template/components/bootstrap/Popovers';
import useDeviceScreen from '@this/template/hooks/useDeviceScreen';
import Header, { HeaderLeft, HeaderRight } from '@this/template/layout/Header/Header';
import Navigation from '@this/template/layout/Navigation/Navigation';
import { pageLayoutTypesPagesMenu } from '@this/template/menu';
import api from '@this/impl/api';
import * as _ from 'lodash';
import User from '@this/template/layout/User/User';
import useDarkMode from '@this/template/hooks/useDarkMode';
import Button, { IButtonProps } from '@this/template/components/bootstrap/Button';
import UserHeader from '@this/template/layout/User/UserHeader';
import Search from './search';

const MainHeader = () => {
	const { width } = useDeviceScreen();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	/* const { data, status, isFetching } = api.Public.useSectionsTree();

	const sectionsTree = data?.map((d) => api.Public.mapSectionsTreeMenu(d));

	const menu: { [k: string]: any } = {};

	 _.forEach(sectionsTree, (r) => {
		menu[r.id] = r;
	});  */

	return (
		<Header>
			<HeaderLeft>
				<Search />
			</HeaderLeft>
			<HeaderRight>
				<div className='row g-3 align-items-center'>
					<div className='col-auto'>
						<UserHeader collapsible={false} />
					</div>
				</div>
			</HeaderRight>
		</Header>
	);
};

export default MainHeader;

import Membership from './membership';
import Contents from './contents';
import Rules from './rules';
import Public from './public';
import Orders from './orders';
import Users from './users';
import Roles from './roles';
import Schedules from './schedule';
import Invoices from './inovices';

export default {
	Public,
	Membership,
	Contents,
	Rules,
	Orders,
	Users,
	Roles,
	Schedules,
	Invoices,
};

import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import MainHeader from '../pages/public/headers/main.header';
import AdminRoutes from './admin/header.routes';

const presentation: RouteProps[] = [
	{
		path: `*`,
		element: <MainHeader />,
	},
];

const header = [...presentation, ...AdminRoutes];

export default header;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getData, patchData } from './base';
import { useEntityQuery } from './entityQuery';
import Rule from './models/rule';
import RuleDefinition, { Condition } from './models/rule.definition';

const useUserRules = () => {
	const queryClient = useQueryClient();
	return {
		unmeets: useQuery<Rule[], Error>(
			['unmeetsUserRules'],
			getData(`/rules/instances/unmeets/all`),
			{
				refetchOnWindowFocus: true,
				refetchOnMount: true,
				retry: true,
			},
		),
		history: useQuery<Rule[], Error>(
			['meetsUserRules'],
			getData(`/rules/instances/meets/all`),
			{
				refetchOnWindowFocus: true,
				refetchOnMount: true,
				retry: true,
			},
		),
		sign: async (id: String) => {
			const result = await patchData(`/rules/instances/${id}/sign`, {});
			if (result.status >= 299) {
				console.error('Error trying to sign rule.');
			}
		},
		reject: async (id: String) => {
			const result = await patchData(`/rules/instances/${id}/reject`, {});
			if (result.status >= 299) {
				console.error('Error trying to sign rule.');
			}
		},
		invalidate: () => {
			queryClient.invalidateQueries(['meetsUserRules']);
			queryClient.invalidateQueries(['unmeetsUserRules']);
		},
	};
};

const useRuleDefinitions = (id?: string) =>
	useEntityQuery<RuleDefinition>({
		name: 'ruleDefinitions',
		path: '/rules/definitions',
		id,
		enabled: true,
		tree: false,
	});

const useDocumentsRuleDefinitions = (id?: string) =>
	useEntityQuery<RuleDefinition>({
		name: 'ruleDefinitions',
		path: '/rules/definitions?type=document-approbal',
		id,
		enabled: true,
		tree: false,
	});

const useDocumentsRule = (documentId: string) =>
	useEntityQuery<Rule>({
		name: 'rule',
		path: `/rules/instances/urn/document:${documentId}`,
		id: documentId,
		enabled: false,
		tree: false,
	});

const useDocumentsRuleUnmeets = (documentId: string) =>
	useQuery<Condition[], Error>(
		['ruleUnmeets'],
		getData(`/rules/instances/urn/document:${documentId}/unmeets`, { defaultOn404: [] }),
		{
			refetchOnWindowFocus: true,
			refetchOnMount: true,
			retry: true,
		},
	);

export default {
	useRuleDefinitions,
	useDocumentsRuleDefinitions,
	useDocumentsRule,
	useDocumentsRuleUnmeets,
	useUserRules,
};

import AdminAside from '@this/impl/pages/admin/aside/admin';
import React, { lazy } from 'react';
import { RouteProps } from 'react-router-dom';

const aside: RouteProps[] = [
	{ path: '/', element: <AdminAside /> },
	{ path: '/admin', element: <AdminAside /> },
	{ path: '/admin/*', element: <AdminAside /> },
];

export default aside;

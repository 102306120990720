import { useQuery } from '@tanstack/react-query';
import { getData } from './base';
import { UsersInterface } from './users';

export interface RolesInterface {
	id: string;
	name: string;
	description: string;
}

export interface ScheduleParametersInterface {
	startDate: Date;
	endDate: Date;
}

export enum ScheduleType {
	Estimate = 'Estimate',
	Work = 'Work',
}

export interface ScheduleInterface {
	technician: UsersInterface;
	status: string;
	orderId: string;
	orderNumber: number;
	start: Date;
	end: Date;
	type: ScheduleType;
	creationDate: Date;
	id: string;
}

const useSchedules = (query: string | '') =>
	useQuery<ScheduleInterface[], Error>(['schedules'], getData(`/orders/schedules${query}`));

const useSchedule = (id: string) =>
	useQuery<ScheduleInterface, Error>(['schedule'], getData(`/membership/roles/${id}`));

export default {
	useSchedules,
	useSchedule,
};

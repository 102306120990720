import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import * as _ from 'lodash';
import api from '@this/impl/api';
import Spinner from '@this/template/components/bootstrap/Spinner';
import Accordion, { AccordionItem } from '@this/template/components/bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Navigation from '../../../../template/layout/Navigation/Navigation';

interface SectionsProps {
	layout?: string;
}

const SectionsNavigation = (props: SectionsProps) => {
	const { layout } = props;
	const { data, status, isFetching } = api.Public.useSectionsTree();

	const sectionsTreeMenu = data
		?.filter((x) => !x.parent && (layout && x.layout === layout && status?.toLocaleLowerCase() !== 'inactive'))
		.sort()
		.map((d) => api.Public.mapSectionsTreeMenu(d));

	const menu: { [k: string]: any } = {};

	_.forEach(sectionsTreeMenu, (r) => {
		menu[r.id] = r;
	});

	return isFetching && !data ? (
		<div style={{ height: 500 }}>
			<div className='placeholder-glow mt-5 gap-2 flex d-flex align-items-stretch flex-column p-2'>
				<div className='placeholder col-10 bg-secondary ms-4 p-2' />
				<div className='placeholder col-10 bg-secondary ms-4 p-2' />
				<div className='placeholder col-10 bg-secondary ms-4 p-2' />
				<div className='placeholder col-10 bg-secondary ms-4 p-2' />
				<div className='placeholder col-10 bg-secondary ms-4 p-2' />
				<div className='placeholder col-10 bg-secondary ms-4 p-2' />
				<div className='placeholder col-10 bg-secondary ms-4 p-2' />
			</div>
		</div>
	) : (

		<div className='m-3'>
			{sectionsTreeMenu?.sort((a: any, b: any) => a.id < b.id ? 1 : 0).map((m) => (
				<Accordion id={m.id} shadow='none' className='aside-accordion' key={m.id}>
					<AccordionItem id='a' title={m.text}>
						<div className='navigation'>
							{m.subMenu?.sort().map((sm: any, k: any) => (
								<div className="navigation-link" key={`${sm.id}-nav-link`}>
									<Link to={sm.path} >
										{sm.text}
									</Link>
								</div>
							))}
						</div>
					</AccordionItem>
				</Accordion>
			))}
		</div>
	);
};

export default SectionsNavigation;

import React, { useContext } from 'react';
import { NavigationLine } from '@this/template/layout/Navigation/Navigation';
import AuthContext from '@this/template/contexts/authContext';
import Sections from './sections.navigation';

const Main = ({}) => {
	const { isLoggedIn } = useContext(AuthContext);

	const logged = isLoggedIn();

	return (
		<>
			{logged && <Sections layout="A" />}
			<NavigationLine />
			{logged && <Sections layout="B" />}
		</>
	);
};

export default Main;

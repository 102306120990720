import React, { SVGProps } from 'react';

const SvgArrowOutward = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path d='m256-240-56-56 384-384H240v-80h480v480h-80v-344L256-240Z' />
		</svg>
	);
};

export default SvgArrowOutward;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getData } from './base';
import Document from './models/document';
import Section from './models/section';
import Structure from './models/structure';

export interface QueryDocuments {
	keywords?: string;
	parent?: string;
	categories?: string[];
	labels?: string[];
}

const useDocuments = (params?: { mostViewed?: boolean }) => {
	const { mostViewed } = params ?? { mostViewed: false };

	const url = `/contents/documents/${mostViewed ? 'most-viewed' : 'search'}`;
	const key = mostViewed ? 'most-viewed' : 'search';

	return useQuery<Document[], Error>(['documents', key], getData(url, { onlyPublic: true }));
};

const useSearchDocuments = (query: QueryDocuments) => {
	const params = [];

	params.push(query.categories ? `categories=${query.categories.join(',')}` : '');
	params.push(query.labels ? `labels=${query.labels.join(',')}` : '');
	params.push(query.keywords ? `q=${query.keywords}` : '');
	params.push(query.parent ? `parent=${query.parent}` : '');

	const queryClient = useQueryClient();

	const key = params.join('&');
	return {
		query: useQuery<Document[], Error>(
			['documents', key],
			getData(`/contents/documents/search?${params.join('&')}`, {
				onlyPublic: true,
			}),
			{ enabled: true, refetchOnWindowFocus: false },
		),
		invalidate: () => {
			queryClient.invalidateQueries(['documents', key]);
		},
	};
};

const useDocument = (id: String) => {
	return useQuery<Document, Error>(
		['documents', id],
		getData(`/contents/documents/${id}`, { onlyPublic: true }),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
		},
	);
};

const useDocumentActions = (id: String) => {};

const useStructure = (id: String) => {
	return useQuery<Structure, Error>(
		['structures', id],
		getData(`/contents/structure/${id}`, { onlyPublic: true }),
	);
};

const useSections = () => {
	return useQuery<Section[], Error>(
		['sections'],
		getData('/contents/sections', { onlyPublic: true }),
	);
};

const useSection = (id: String) => {
	return useQuery<Section, Error>(
		['sections', id],
		getData(`/contents/sections/${id}`, { onlyPublic: true }),
	);
};

const useSectionChildren = (id: String) => {
	return useQuery<Section[], Error>(
		['sections', id],
		getData(`/contents/sections/${id}/children`, { onlyPublic: true }),
	);
};

const useSectionsTree = () => {
	return useQuery<any[], Error>(
		['sections', { type: 'tree' }],
		getData('/contents/sections/tree', { onlyPublic: true }),
	);
};

const mapSectionsTreeMenu = (e: any) => ({
	id: `section${e._id}`,
	text: e.name ?? 'unkown',
	path: resolveSectionElement(e),
	icon: 'Dashboard',
	subMenu:
		e.children && e.children.length > 0
			? e.children
					?.filter((x: any) => x.status?.toLocaleLowerCase() !== 'inactive')
					.map((c: { children: any }) => mapSectionsTreeMenu(c))
			: null,
});

const resolveSectionElement = (e: any) => {
	if (e && e.module) {
		const { type, properties } = e.module;
		if (type) {
			const { id } = properties ?? { id: e._id };

			switch (type) {
				case 'sections':
					return `/sections/${id}?sectionId=${e._id}`;
				case 'structure':
					return `/structures/${id}?sectionId=${e._id}`;
				case 'document':
					return `/documents/${id}?sectionId=${e._id}`;
				case 'document.list':
					return `/documents/`;
				case 'url':
					return properties.url;
				default:
					return 'unkown';
			}
		} else {
			return 'unkown';
		}
	}

	return null;
};

export default {
	useSections,
	useSection,
	useSectionsTree,
	useDocument,
	useStructure,
	useSearchDocuments,
	mapSectionsTreeMenu,
	useSectionChildren,
	useDocuments,
};

import React, { lazy } from 'react';

const LOGIN = lazy(() => import('../pages/login/Login'));
const APPROVE_ESTIMATE = lazy(() => import('../pages/approve-estimate/ApproveEstimate'));
const presentation = [
	{
		path: '/login',
		element: <LOGIN />,
	},
	{
		path: "/approve-estimate",
		element: <APPROVE_ESTIMATE />
	}
];

const contents = [...presentation];

export default contents;

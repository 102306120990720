import React, { useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import Popovers from '../../components/bootstrap/Popovers';

interface UserProps {
	collapsible: boolean;
}

const UserHeader = (props: UserProps) => {
	const { userData, logout } = useContext(AuthContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	const { collapsible } = props;

	return (
		<>
			<div
				className={classNames('user-header', { open: collapseStatus })}
				role='presentation'>
				{/* <div className='user-avatar'>
					<img
						srcSet={userData?.srcSet}
						src={userData?.src}
						alt='Avatar'
						width={128}
						height={128}
					/>
				</div> */}
				<div className='user-info'>
					<div className='user-name'>{userData?.name}</div>
					<div className='user-sub-title'>{userData?.email ?? ''}</div>
				</div>
			</div>
			{collapsible && (
				<Collapse isOpen={collapseStatus} className='user-menu'>
					<nav aria-label='aside-bottom-user-menu'>
						<div className='navigation'>
							{/* <div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() =>
									navigate(
										`/`,
										// @ts-ignore
										handleItem(),
									)
								}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='AccountBox' className='navigation-icon' />
										<span className='navigation-text'>
											{t('menu:Profile') as ReactNode}
										</span>
									</span>
								</span>
							</div> */}
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDarkModeStatus(!darkModeStatus);
									handleItem();
								}}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
											color={darkModeStatus ? 'info' : 'warning'}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{darkModeStatus
												? (t('menu:DarkMode') as ReactNode)
												: (t('menu:LightMode') as ReactNode)}
										</span>
									</span>
								</span>
							</div>
						</div>
					</nav>
					<NavigationLine />
					<nav aria-label='aside-bottom-user-menu-2'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									if (logout) {
										logout();
									}
								}}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='Logout' className='navigation-icon' />
										<span className='navigation-text'>
											{t('menu:Logout') as ReactNode}
										</span>
									</span>
								</span>
							</div>
						</div>
					</nav>
				</Collapse>
			)}
		</>
	);
};

export default UserHeader;

import React, { useEffect, useState } from 'react';
import moment from 'moment';

const Clock = ({}) => {
	const [date, setDate] = useState(new Date());

	const refreshClock = () => {
		setDate(new Date());
	};

	useEffect(() => {
		const timerId = setInterval(refreshClock, 1000 * 60);
		return function cleanup() {
			clearInterval(timerId);
		};
	}, []);

	const x = moment(date);

	return (
		<div className='aside-clock'>
			<div className='p-20 aside-clock d-flex flex-row align-items-center'>
				<div className='pe-3'>
					<span className='fs-9 tc-white fw-lgt'>{x.format('HH:mm')}</span>
				</div>
				<div>
					<p className='m-0 tc-01 fs-5 lh-sm'>
						{x.format('dddd')}
						<br />
						{x.format('DD MMMM YYYY')}
					</p>
				</div>
			</div>
		</div>
	);
};
export default Clock;

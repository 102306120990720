/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext, ReactNode, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import Popovers from '../../components/bootstrap/Popovers';

interface UserProps {
	collapsible: boolean;
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}

const User: FC<UserProps> = ({ collapsible, asideStatus, setAsideStatus }) => {
	const { userData, logout } = useContext(AuthContext);
	console.log('userData', userData);
	const navigate = useNavigate();

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<nav aria-label='aside-bottom-user-menu-2'>
			<ul className='navigation'>
				<li className='navigation-item cursor-pointer mb-2'>
					<span className='navigation-link-info navigation-link-pill'>
						<span className='navigation-link'>
							{/* <Icon icon='Logout' className='navigation-icon' /> */}
							<span className='navigation-text'>{userData.name}</span>
						</span>
					</span>
				</li>
				<li className='navigation-item cursor-pointer mb-2'>
					<span
						className='navigation-link-info navigation-link-pill'
						onClick={() => {
							if (logout) {
								logout();
							}
						}}
						role='button'>
						<span className='navigation-link'>
							<Icon icon='Logout' className='navigation-icon' />
							<span className='navigation-text'>Log Out</span>
						</span>
					</span>
				</li>
				{/* <li className='navigation-item cursor-pointer mb-2'>
					<span
						className='navigation-link-info navigation-link-pill'
						onClick={() => setAsideStatus(!asideStatus)}
						role='button'>
						<span className='navigation-link'>
							<Icon
								icon='FirstPage'
								className='navigation-icon brand-aside-toggle-close'
							/>
							<Icon
								icon='LastPage'
								className='navigation-icon brand-aside-toggle-open'
							/>
							<span className='navigation-text'>
								{asideStatus ? 'Ocultar' : 'Fijar menu'}
							</span>
						</span>
					</span>
				</li> */}
			</ul>
		</nav>
	);
};

export default User;

import React, { useEffect, useState } from 'react';
import Badge from '@this/template/components/bootstrap/Badge';
import Button from '@this/template/components/bootstrap/Button';
import Input from '@this/template/components/bootstrap/forms/Input';
import InputGroup from '@this/template/components/bootstrap/forms/InputGroup';
import Icon from '@this/template/components/icon/Icon';

interface TagsFieldProps {
	onChange?: (tags: string[]) => void;
	id: string;
	name: string;
	value?: string[];
}

const TagsField = (props: TagsFieldProps) => {
	const { onChange, name, id, value } = props;
	const [tags, setTags] = useState<string[]>(value ?? []);
	const [textValue, setTextValue] = useState<string>('');

	const handleTagsInputChange = (e: any) => {
		setTextValue(e.target.value);
	};

	const handleTagsInputKeyDown = (e: any) => {
		if (e.key === 'Enter') {
			addTags(e.target.value);
			setTextValue('');
		}
	};

	const handleAddTags = (label: string) => {
		addTags(textValue);
	};

	const handleRemoveTag = (tag: string) => removeTag(tag);

	const removeTag = (tag: string) => {
		const removedTags = tags.filter((l) => l !== tag);
		setTags(removedTags);
		if (onChange) onChange(removedTags);
	};

	const addTags = (input: string) => {
		const newTags: string[] = [];
		newTags.push(...input.split(',').map((l) => l.trim()));
		newTags.push(...input.split(' ').map((l) => l.trim()));
		// Eliminate duplicated tags from newTags
		const uniqueTags = newTags.filter((v, i, a) => a.indexOf(v) === i);
		// Eliminate commas and spaces from uniqueTags
		uniqueTags.forEach((tag) => {
			tag = tag.replace(',', '');
			tag = tag.replace(' ', '');
		});
		// setTags((v) => [...v, ...uniqueTags]);
		if (onChange) onChange([...tags, ...uniqueTags]);
	};

	useEffect(() => {
		setTags(value ?? []);
	}, [value]);

	const Tags = (tagsProps: { elements: string[] }) => {
		const { elements } = tagsProps;
		return elements?.map((l: string, i) =>
			l !== '' ? (
				<Badge color='primary' className='input-group-text p-3' key={i}>
					{l}
					<span>
						<Icon icon='delete' className='ms-2' onClick={() => handleRemoveTag(l)} />
					</span>
				</Badge>
			) : null,
		);
	};

	return (
		<InputGroup>
			<Tags elements={tags} />
			<Input
				id={id}
				name={name}
				value={textValue}
				onKeyDown={handleTagsInputKeyDown}
				onChange={handleTagsInputChange}
			/>
			<Button
				color='primary'
				icon='Add'
				className='mt-0'
				onClick={handleAddTags}
				isDisable={!textValue}
			/>
		</InputGroup>
	);
};

export default TagsField;

import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { getData, patchData, postData } from './base';

export interface IEntityQuery<T> {
	all: UseQueryResult<T[], Error>;
	query: UseQueryResult<T[], Error>;
	tree?: UseQueryResult<T[], Error>;
	one?: UseQueryResult<T, Error>;
	save: (entity: T) => Promise<any>;
	invalidate: () => void;
}

export const useEntityQuery = <T>({
	name,
	path,
	query,
	id,
	tree,
	enabled,
	refetchOnMount,
	refetchOnWindowFocus,
}: {
	name: string;
	path: string;
	query?: string;
	id?: string;
	tree?: boolean;
	enabled?: boolean;
	refetchOnMount?: boolean;
	refetchOnWindowFocus?: boolean;
}): IEntityQuery<T> => {
	const queryClient = useQueryClient();

	return {
		tree: useQuery<T[], Error>([`${name}tree`], getData(`${path}/tree`), {
			refetchOnWindowFocus,
			refetchOnMount,
			enabled: tree && enabled,
		}),
		all: useQuery<T[], Error>([name], getData(`${path}`), {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			enabled,
		}),
		query: useQuery<T[], Error>([name, query], getData(`${path}/search?${query}`), {
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			enabled: query !== undefined,
		}),
		one: useQuery<T, Error>([name, id], getData(`${path}/${id}`), {
			refetchOnWindowFocus: false,
			enabled: id !== undefined,
			refetchOnMount,
		}),
		save: async (entity: any) => {
			if (entity._id || entity.id) {
				const result = await patchData(path, entity);
				return result.data;
			}

			const result = await postData(path, entity);
			return result.data;
		},
		invalidate: () => {
			queryClient.invalidateQueries([name]);
			queryClient.invalidateQueries([`${name}tree`]);
			if (id) {
				queryClient.invalidateQueries([name, id]);
			}
		},
	};
};

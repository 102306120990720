import { useQuery } from '@tanstack/react-query';
import { getData } from './base';

export interface UsersInterface {
	id: string;
	_id: string;
	fullName: string;
	name?: string;
	firstName: string;
	lastName: string;
	email: string;
	hourRate: number;
	phoneNumber: string;
	type: string;
	privileges: string[];
	roles: any[];
}

const useUsers = () => useQuery<UsersInterface[], Error>(['users'], getData('/membership/users'));

const useUser = (id: string) =>
	useQuery<UsersInterface, Error>(['user'], getData(`/membership/users/${id}`));

export default {
	useUsers,
	useUser,
};

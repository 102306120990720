import React, { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';
import Loading from '@this/template/components/loading';

export interface IPageProps {
	children: ReactNode;
	container?: boolean | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'fluid';
	className?: string;
	visible?: boolean;
	loading?: boolean;
}
const Page = forwardRef<HTMLDivElement, IPageProps>(
	(
		{ children, className, container = 'xxl', visible = true, loading = false, ...props },
		ref,
	) => {
		return (
			<div
				ref={ref}
				className={classNames('page', className, {
					[`container${typeof container === 'string' ? `-${container}` : ''}`]: container,
				})}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				<Loading visible={loading} />
				{visible && children}
			</div>
		);
	},
);

export default Page;
